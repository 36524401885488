body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-spin {
  -webkit-animation: icon-spin 3s infinite linear;
          animation: icon-spin 3s infinite linear;
}

.icon-spin-anti {
  -webkit-animation: icon-spin-anti 1s infinite linear;
          animation: icon-spin-anti 1s infinite linear;
}
.jsonedit button{
  color: white;
  text-decoration: none;
  border-radius: 7px;
  border: none;
  text-transform: lowercase;
  height:27px;
}
.jsonedit select{
  margin-right: 1rem;
}

.jsonedit div[class^="styles-module_clickNode"] {
  height:30px;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes icon-spin-anti {
  0% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359eg);
  }
  100% {
    -webkit-transform: rotate(0eg);
            transform: rotate(0deg);
  }
}

@keyframes icon-spin-anti {
  0% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.MuiButtonBase-root { 
  padding: 8px !important; 
}
.MuiDataGrid-row.Mui-selected {
  background-color: rgba(156, 39, 176, 0.1) !important;
}
.MuiDataGrid-row.Mui-selected:hover {
  background-color: rgba(156, 39, 176, 0.5) !important;
}
 
.uplift-report .MuiDataGrid-columnHeader{
  background-color: rgba(156, 39, 176, 0.5); 
  color: white;

}

.uplift-report .MuiDataGrid-iconSeparator:last-child {
  visibility: hidden;
}

.styles-module_overlay__3xf0A + div {
  bottom:10% !important;
  right:50% !important;
}

.styles-module_overlay__3xf0A{
  height: 50% !important;
}
.styles-module_editorContainer__3rgkN {
  height: 500px !important;
}
